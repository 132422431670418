<template>
  <div>
    <template v-if="activeCount || passiveCount">
      <div class="row">
        <div class="col-auto">
          <i class="fas fa-check fa-sm text-success mr-1 count-icon"></i>
          <span>{{ activeCount }}</span>
        </div>
        <div class="col-auto">
          <i class="fas fa-times fa-sm text-danger mr-1 count-icon"></i>
          <span>{{ passiveCount }}</span>
        </div>
      </div>
    </template>
    <template v-else> - </template>
  </div>
</template>
<script>
export default {
  name: "TableSplitCount",
  props: {
    row: {
      default: null,
    },
  },
  computed: {
    activeCount() {
      return this.row.active_count;
    },
    passiveCount() {
      return this.row.passive_count;
    },
  },
};
</script>
<style lang="scss">
.count-icon {
  width: 15px;
}
</style>